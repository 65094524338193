import { render, staticRenderFns } from "./InstitutionCompetenceForm.vue?vue&type=template&id=809d081c"
import script from "./InstitutionCompetenceForm.vue?vue&type=script&lang=js"
export * from "./InstitutionCompetenceForm.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports