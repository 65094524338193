<template>
  <div>
    <h4 v-if="isNaN(institution_competence.id) && show_title">
      Crear Competencia Genérica
    </h4>
    <h4 v-else-if="show_title">Editar Competencia Genérica</h4>
    <div>
      <b-form>
        <b-form-group
          :label="$getVisibleNames('manual.dimension', false, 'Dimensión')"
          label-for="input-dimension"
          label-cols="4"
          label-cols-sm="3"
        >
          <b-form-input
            id="input-dimension"
            v-model="$v.institution_competence.name.$model"
            :state="validateState('name')"
            aria-describedby="input-dimension-feedback"
            size="sm"
          ></b-form-input>
          <b-form-invalid-feedback id="input-dimension-feedback"
            >Este campo es obligatorio.</b-form-invalid-feedback
          >
        </b-form-group>
        <b-form-group
          label="Nombre Corto"
          label-for="input-short-name"
          label-cols="4"
          label-cols-sm="3"
        >
          <b-form-input
            id="input-short-name"
            v-model="$v.institution_competence.short_name.$model"
            :state="validateState('short_name')"
            aria-describedby="input-short-name-feedback"
            size="sm"
          ></b-form-input>
          <b-form-invalid-feedback id="input-short-name-feedback"
            >Este campo bede tener dos caracteres.</b-form-invalid-feedback
          >
        </b-form-group>
        <b-form-group
          label="Color"
          label-for="input-color"
          label-cols="4"
          label-cols-sm="3"
        >
          <b-form-input
            id="input-color"
            v-model="$v.institution_competence.color.$model"
            :state="validateState('color')"
            aria-describedby="input-color-feedback"
            size="sm"
            type="color"
          ></b-form-input>
          <b-form-invalid-feedback id="input-color-feedback"
            >Este campo es obligatorio.</b-form-invalid-feedback
          >
        </b-form-group>
        <b-form-group
          label="Acción"
          label-for="input-action"
          label-cols="4"
          label-cols-sm="3"
        >
          <b-form-input
            id="input-action"
            v-model="$v.institution_competence.action.$model"
            :state="validateState('action')"
            aria-describedby="input-action-feedback"
            size="sm"
          ></b-form-input>
          <b-form-invalid-feedback id="input-action-feedback"
            >Este campo es obligatorio.</b-form-invalid-feedback
          >
        </b-form-group>
        <b-form-group
          label="Contenido"
          label-for="input-content"
          label-cols="4"
          label-cols-sm="3"
        >
          <b-form-input
            id="input-content"
            v-model="$v.institution_competence.content.$model"
            :state="validateState('content')"
            aria-describedby="input-content-feedback"
            size="sm"
          ></b-form-input>
          <b-form-invalid-feedback id="input-content-feedback"
            >Este campo es obligatorio.</b-form-invalid-feedback
          >
        </b-form-group>
        <b-form-group
          label="Condición"
          label-for="input-condition"
          label-cols="4"
          label-cols-sm="3"
        >
          <b-form-input
            id="input-condition"
            v-model="$v.institution_competence.condition.$model"
            :state="validateState('condition')"
            aria-describedby="input-condition-feedback"
            size="sm"
          ></b-form-input>
          <b-form-invalid-feedback id="input-condition-feedback"
            >Este campo es obligatorio.</b-form-invalid-feedback
          >
        </b-form-group>

        <div class="row">
          <div
            v-can="'mesh.delete_institutioncompetence'"
            v-if="show_delete_button && !isNaN(institution_competence.id)"
            class="col"
            style="text-align: left"
          >
            <b-button
              class="mr-1"
              size="sm"
              variant="danger"
              @click="deleteInstitutionCompetence"
              >Eliminar</b-button
            >
          </div>
          <div
            v-can="
              'mesh.change_institutioncompetence mesh.add_institutioncompetence'
            "
            v-if="show_save_button"
            class="col"
            style="text-align: right"
          >
            <b-button class="mr-1" size="sm" @click="saveInstitutionCompetence"
              >Guardar</b-button
            >
          </div>
        </div>
      </b-form>
    </div>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { generateUniqueId } from "@/utils/utils";
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";

export default {
  name: "InstitutionCompetenceForm",
  mixins: [validationMixin],
  props: {
    // institution_id: {
    //   type: Number,
    //   default: 1,
    // },
    InstitutionCompetence: {
      type: Object,
    },
    show_save_button: {
      type: Boolean,
      default: true,
    },
    show_delete_button: {
      type: Boolean,
      default: false,
    },
    show_title: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      institution_competence: {
        id: this.InstitutionCompetence
          ? this.InstitutionCompetence.id
          : generateUniqueId(),
        action: this.InstitutionCompetence
          ? this.InstitutionCompetence.action
          : "",
        content: this.InstitutionCompetence
          ? this.InstitutionCompetence.content
          : "",
        condition: this.InstitutionCompetence
          ? this.InstitutionCompetence.condition
          : "",
        name: this.InstitutionCompetence ? this.InstitutionCompetence.name : "",
        short_name: this.InstitutionCompetence
          ? this.InstitutionCompetence.short_name
          : "",
        color: this.InstitutionCompetence
          ? this.InstitutionCompetence.color
          : "#a0a0a0",
        institution: this.InstitutionCompetence
          ? this.InstitutionCompetence.school
          : this.$store.getters.getInstitutionId,
      },
    };
  },
  validations: {
    institution_competence: {
      action: {
        required,
      },
      content: {
        required,
      },
      condition: {
        required,
      },
      name: {
        required,
      },
      short_name: {
        required,
        maxLength: maxLength(2),
      },
      color: {
        required,
      },
      institution: {
        required,
      },
    },
  },
  computed: {
    ...mapGetters({
      faculties: names.FACULTIES,
    }),
  },
  methods: {
    validateState(key) {
      const { $dirty, $error } = this.$v.institution_competence[key];
      return $dirty ? !$error : null;
    },
    saveInstitutionCompetence() {
      this.$v.institution_competence.$touch();
      if (this.$v.institution_competence.$anyError) {
        return;
      }
      if (isNaN(this.institution_competence.id))
        this.createInstitutionCompetence();
      else this.updateInstitutionCompetence();
    },
    createInstitutionCompetence() {
      this.$store
        .dispatch(
          names.POST_INSTITUTION_COMPETENCE,
          this.institution_competence
        )
        .then((response) => {
          this.institution_competence = response;
          this.$emit("created", this.institution_competence);
        });
    },
    updateInstitutionCompetence() {
      this.$store
        .dispatch(
          names.UPDATE_INSTITUTION_COMPETENCE,
          this.institution_competence
        )
        .then((response) => {
          this.institution_competence = response;
          this.$emit("updated", this.institution_competence);
        });
    },
    deleteInstitutionCompetence() {
      this.$swal({
        title: "¿Está seguro de que desea eliminar la Competencia Genérica?",
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch(
              names.DELETE_INSTITUTION_COMPETENCE,
              this.institution_competence.id
            )
            .then(() => {
              this.$emit("deleted", this.institution_competence);
            });
        }
      });
    },
  },
  watch: {},
  created() {},
  mounted() {},
};
</script>

<style>
</style>